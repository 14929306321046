<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t('message.update_movement') }}</span>
      <el-button
        type="success"
        size="small"
        class="mr-1"
        :loading="loadingButton"
        :disabled="loadingButton"
        @click="submit()"
        >{{ $t('message.refresh') }}</el-button
      >
      <el-button type="warning" icon="el-icon-close" size="small" @click="resetForm('form')">{{ $t('message.close') }}</el-button>
    </header>

    <div class="body__modal">
      <el-form ref="form" :model="form" size="small" class="aticler_m stylekhanForm">
        <el-card class="box-card mb-4">
      

          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item :label="$t('message.warehouse')">
                <el-row :gutter="5">
                  <el-col :span="22">
                    <el-form-item class="mb-0">
                      <mainwarehouse ref="mwareh" v-model="form.mainwarehouse_id" :mainwarehouse_id="form.mainwarehouse_id"></mainwarehouse>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="$t('message.warehouse_movement')">
                <el-row :gutter="5">
                  <el-col :span="22">
                    <el-form-item class="mb-0">
                      <warehouse ref="wareh" v-model="form.warehouse_id" :warehouse_id="form.warehouse_id"></warehouse>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-card class="box-card mb-4">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-table border :data="items" v-loading="loadingItems" :row-class-name="tableRowClassName">
                <el-table-column label="#" width="30">
                  <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.name')">
                  <template slot-scope="scope">{{ scope.row.product.name }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.available')">
                  <template slot-scope="scope">{{ scope.row.available }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.quantity')">
                  <template slot-scope="scope">
                    <el-input v-if="scope.row.is_new" v-model="scope.row.quantity"></el-input>
                    <span v-else>{{ scope.row.quantity }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.actions')">
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.is_new"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="removeWriteOffItem(scope.row)"
                    ></el-button>
                    <el-button
                      v-if="!scope.row.is_new"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="destroyWriteOffItem(scope.row)"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <el-row :gutter="25">
              <el-col :span="11"  class="mt-3">
                <el-form-item :label="$t('message.products')">
                  <products @append="append" v-model="form.product_id" :product_id="form.product_id"></products>
                </el-form-item>
              </el-col>
            </el-row>
        </el-card>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import drawerChild from '@/utils/mixins/drawer-child';
import drawer from '@/utils/mixins/drawer';
import products from '@/components/selects/products';
import form from '@/utils/mixins/form';
import mainwarehouse from '@/components/selects/mainwarehouse';
import warehouse from '@/components/selects/warehouse';

export default {
  mixins: [drawerChild, drawer, form],
  components: {
    mainwarehouse,
    warehouse,
    products,
  },
  props: ['moving'],
  data() {
    return {
      loadingItems: false,
      old_items: [],
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      model: 'movings/model',
      rules: 'movings/rules',
      columns: 'movings/columns',
    }),
  },
  methods: {
    ...mapActions({
      showProductRemainder: 'movings/showrem',
      showWriteOff: 'movings/show',
      updateWriteOff: 'movings/update',
      destroyItem: 'movings/destroyItem',
    }),
    afterClosed() {
      this.items = [];
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.close();
    },
    append(product) {
      const item = this.items.find((item) => item.product_id == product.id);
      this.loadingItems = true;
      const payload = {product_id: product.id, warehouse_id: this.form.mainwarehouse_id};
      this.showProductRemainder(payload)
        .then((response) => {
          const product = response.data.product;
          if (!item) {
            this.items.push({ 
              is_new: true,
              product_id: product.id,
              available: product.available ? product.available : 0,
              quantity: 0,
              product: {
                name: product.name,
              },
            });
          }
          this.loadingItems = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    afterOpened() {
      this.$refs.mwareh.disableSelect()
      this.$refs.wareh.disableSelect()
      this.load();
    },
    load() {
      this.loadingItems = true;
      this.showWriteOff(this.moving.id)
        .then((response) => {
          if (response.data.moving.movingitems) {
            this.items.push(
              ...response.data.moving.movingitems.map((item) => ({
                id: item.id,
                is_new: false,
                product_id: item.product_id,
                available: item.product.available ? item.product.available : 0,
                quantity: item.quantity,
                product: {
                  name: item.product.name,
                },
              }))
            );
            this.loadingItems = false;
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    submit() {
      const payload = {
        id: this.form.id,
        mainwarehouse_id: this.form.mainwarehouse_id,
        warehouse_id: this.form.warehouse_id,
        old_items: this.items.filter((item) => !item.is_new),
        new_items: this.items.filter((item) => item.is_new),
      };

      this.updateWriteOff(payload)
        .then((response) => {
          this.$alert(response);
          this.close();
        })
        .catch();
    },
    removeWriteOffItem(item) {
      const index = this.items.indexOf(item);
      this.items.splice(index, 1);
    },
    destroyWriteOffItem(item) {
      let id = item.id;
      this.destroyItem(id)
        .then(() => {
          this.items.splice(this.items.indexOf(item), 1);
        })
        .catch(error => {
          this.$alert(error);
        });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.available == 0) {
        return 'warning-row';
      }
    },
  },
};
</script>

<style>
.el-table tr.warning-row {
  background-color: #e6a23c;
}
</style>
